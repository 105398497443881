import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Productdetails = () =>{

  // const TagButton = ({ names, realName, handleSetTag, tagActive }) => {
  //   return (
  //     <button
  //       className={`list-btn ${tagActive ? "active" : "null"}`}
  //       onClick={() => handleSetTag(names)}
  //     >
  //       {realName}
  //     </button>
  //   );
  // };
 return(
  <React.Fragment>
    <Helmet>
      <title>Products - Blinds | Shutters | Curtains | Birk</title>
      <meta
        property="og:title"
        content="Products - Blinds | Shutters | Curtains | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="We have products like Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="We have products like Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://birk.itechnotion.com/product" />
      <link rel="canonical" href="http://birk.itechnotion.com/product" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Our Product</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">Roller Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
               {/* <TagButton
                    className="list-btn"
                    names="all"
                    realName="All"
                    handleSetTag={setTag}
                    tagActive={tag === "all" ? true : false}
                  /> */}
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12">
              <div className="pro-start-content">
                <h5 className="font-weight-bold mb-2">
                  We're proud to be an innovator in the custom window furnishing
                  industry.
                </h5>
                <p>
                  With a wealth of knowledge and experience in both residential
                  and commercial applications, we're certain we'll have a
                  solution for your unique project.
                </p>
                <p>
                  Our extensive range of quality, stylish, and cost effective
                  furnishings include roller blinds, roman blinds, venetian
                  blinds, curtains, pelmets, shutters, external blinds and
                  motorisation. All made to measure, ensuring a perfect fit
                  every time. We invite you to explore our full range.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/roller-blinds" className="text-decoration-none">
                <div className="product-box">
                  <StaticImage
                    src="../images/broller.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>Roller Blinds</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/roman-blinds" className="text-decoration-none">
                <div className="product-box">
                  <StaticImage
                    src="../images/bromans.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>Roman Blinds</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/curtains" className="text-decoration-none">
                <div className="product-box">
                  <StaticImage
                    src="../images/bcurtains.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>Curtains</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/shutters" className="text-decoration-none ">
                <div className="product-box">
                  <StaticImage
                    src="../images/bshutters.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>Shutters</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/venetians" className="text-decoration-none">
                <div className="product-box">
                  <StaticImage
                    src="../images/bvenetians.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>Venetians</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/external-range" className="text-decoration-none">
                <div className="product-box">
                  <StaticImage
                    src="../images/brange.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>External Range</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4 item">
              <Link to="/automation" className="text-decoration-none">
                <div className="product-box">
                  <StaticImage
                    src="../images/bautomation1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="product-content">
                    <h3>Automation</h3>
                    <p>view this product</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </React.Fragment>
);
            }
export default Productdetails;
